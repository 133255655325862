import React, { useState, useEffect } from 'react';
import './StudentTable.css';

interface Student {
  studentName: string;
  grade: string | null;
  submissionId: string;
  link: string;
  status: string;
}

interface StudentTableProps {
  students: Student[];
  onUpdateStatus: (submissionId: string, status: string) => void;
}

const statusOptions = [
  { value: "TURNED_IN", label: "Odevzdáno", color: "#28a745", backgroundColor: "#e3fcec" },
  { value: "NOT_TURNED_IN", label: "Nesplněno", color: "#dc3545", backgroundColor: "#f8d7da" },
  { value: "TURNED_IN_LATE", label: "Odevzdáno pozdě", color: "#ffa407", backgroundColor: "#fff3cd" },
  { value: "EXCUSED", label: "Omluveno", color: "#6f42c1", backgroundColor: "#f3e6ff" }
];

const StudentTable: React.FC<StudentTableProps> = ({ students, onUpdateStatus }) => {
  const [studentStatus, setStudentStatus] = useState<{ [submissionId: string]: string }>(() =>
    students.reduce((acc, student) => {
      acc[student.submissionId] = student.status;
      return acc;
    }, {} as { [submissionId: string]: string })
  );
  const [isUnlocked, setIsUnlocked] = useState(false);

  useEffect(() => {
    setStudentStatus(
      students.reduce((acc, student) => {
        acc[student.submissionId] = student.status;
        return acc;
      }, {} as { [submissionId: string]: string })
    );
  }, [students]);

  const handleStatusChange = (submissionId: string, newStatus: string) => {
    setStudentStatus(prev => ({ ...prev, [submissionId]: newStatus }));
    onUpdateStatus(submissionId, newStatus);
  };

  const toggleLock = () => {
    setIsUnlocked(prev => !prev);
  };

  const getStatusStyles = (status: string) => {
    const option = statusOptions.find(opt => opt.value === status);
    return option ? { color: option.color, backgroundColor: option.backgroundColor } : {};
  };

  return (
    <div className="student-table-container">
      <div className="student-card header-row">
    <div className="header-item">Jméno a příjmení</div>
    <div className="header-item status-header">Stav odevzdání</div>
    <div className="header-item grade-header">Hodnocení</div>
    <div className="lock-icon" onClick={toggleLock}>
      {isUnlocked ? (
        <svg fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M368,0c-61.758,0-112,50.242-112,112v112H64c-17.672,0-32,14.328-32,32v224c0,17.672,14.328,32,32,32h288 c17.672,0,32-14.328,32-32V256c0-17.672-14.328-32-32-32h-32V112c0-26.469,21.531-48,48-48c26.469,0,48,21.531,48,48v80 c0,17.672,14.328,32,32,32c17.672,0,32-14.328,32-32v-80C480,50.242,429.758,0,368,0z M224,397.063V432c0,8.836-7.164,16-16,16 c-8.836,0-16-7.164-16-16v-34.938c-18.602-6.613-32-24.195-32-45.063c0-26.512,21.488-48,48-48s48,21.488,48,48 C256,372.867,242.602,390.449,224,397.063z"></path>
        </g>
      </svg>
      ) : (
        <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path>
              </g>
            </svg>
      )}
    </div>
  </div>

      <div className="student-table">
        {students.map((student) => (
          <div key={student.submissionId} className="student-card">
              <div className="student-name">{student.studentName}</div>
            <div className="student-actions">
              {isUnlocked ? (
                <select
                  value={studentStatus[student.submissionId]}
                  onChange={(e) => handleStatusChange(student.submissionId, e.target.value)}
                  className="status-dropdown"
                  style={getStatusStyles(studentStatus[student.submissionId])}
                >
                  {statusOptions.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                      style={{ color: option.color }}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="status-text" style={getStatusStyles(studentStatus[student.submissionId])}>
                  <span className="status-dot" style={{ backgroundColor: getStatusStyles(studentStatus[student.submissionId]).color }}></span>
                  {statusOptions.find(opt => opt.value === studentStatus[student.submissionId])?.label}
                </div>
              )}
            </div>
            <div className="student-grade">{student.grade !== null ? student.grade : '-'}</div>
            <div className="student-actions">
              <a href={student.link} className="link-icon" target="_blank" rel="noopener noreferrer">
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.975 14.51a1.05 1.05 0 0 0 0-1.485 2.95 2.95 0 0 1 0-4.172l3.536-3.535a2.95 2.95 0 1 1 4.172 4.172l-1.093 1.092a1.05 1.05 0 0 0 1.485 1.485l1.093-1.092a5.05 5.05 0 0 0-7.142-7.142L9.49 7.368a5.05 5.05 0 0 0 0 7.142c.41.41 1.075.41 1.485 0zm2.05-5.02a1.05 1.05 0 0 0 0 1.485 2.95 2.95 0 0 1 0 4.172l-3.5 3.5a2.95 2.95 0 1 1-4.171-4.172l1.025-1.025a1.05 1.05 0 0 0-1.485-1.485L3.87 12.99a5.05 5.05 0 0 0 7.142 7.142l3.5-3.5a5.05 5.05 0 0 0 0-7.142 1.05 1.05 0 0 0-1.485 0z" fill="#000000"/>
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentTable;
