import React from 'react';
import './StudentCourseworkTable.css';

interface CourseWork {
  courseWorkId: string;
  courseWorkTitle: string;
  grade: string | null;
  gradeCategory: string | null;
  link: string;
  status: string;
  deadline: string | null; // Allow for null to represent no deadline
  optional: boolean;
}

interface StudentCourseworkTableProps {
  courseTitle: string;
  courseWorks: CourseWork[];
}

// Utility function to format deadline to Prague time
const formatToPragueTime = (datetimeString: string | null): string => {
  if (!datetimeString) return 'Nenastaven';

  const date = new Date(datetimeString);

  // Convert to Prague time using toLocaleString
  return date.toLocaleString('cs-CZ', { 
    timeZone: 'Europe/Prague',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

// Function to convert numeric grade to text description
const getGradeText = (grade: string | null, category: string | null): string => {
  if (category == "Splněno/Nesplněno") {
    switch (grade) {
      case '0':
        return 'Neodevzdáno';
      case '1':
        return 'Nesplněno';
      case '4':
        return 'Splněno';
      default:
        return 'Ještě nehodnoceno';
    }
  }
  else {
    switch (grade) {
      case '0':
        return 'Neodevzdáno';
      case '1':
        return 'Neovládám';
      case '2':
        return 'Ovládám s obtížemi';
      case '3':
        return 'Ovládám';
      case '4':
        return 'Ovládám s přehledem';
      default:
        return 'Ještě nehodnoceno';
    }
  }
  
};

const statusOptions = [
  { value: "TURNED_IN", label: "Odevzdáno", color: "#28a745", backgroundColor: "#e3fcec" },
  { value: "NOT_TURNED_IN", label: "Nesplněno", color: "#dc3545", backgroundColor: "#f8d7da" },
  { value: "TURNED_IN_LATE", label: "Odevzdáno pozdě", color: "#ffa407", backgroundColor: "#fff3cd" },
  { value: "EXCUSED", label: "Omluveno", color: "#6f42c1", backgroundColor: "#f3e6ff" }
];

const getStatusStyles = (status: string) => {
  const option = statusOptions.find(opt => opt.value === status);
  return option ? { color: option.color, backgroundColor: option.backgroundColor } : {};
};

const StudentCourseworkTable: React.FC<StudentCourseworkTableProps> = ({ courseTitle, courseWorks }) => {
  return (
    <div className="sct-table-container">
      <h2 className="sct-course-title">{courseTitle}</h2>
      <div className="sct-rows">
        {courseWorks.map((work) => (
          <div key={work.courseWorkId} className="sct-card">
            <div className="sct-name">
              <a href={work.link} target="_blank" rel="noopener noreferrer">
                {work.courseWorkTitle}
              </a>
            </div>
            <div className="sct-deadline">
              <span>Deadline: {formatToPragueTime(work.deadline)}</span>
              <span className="sct-optional">{work.optional ? "Volitelné" : "Povinné"}</span>
            </div>
            <div className="sct-status-grade">
              <div className="sct-status-text" style={getStatusStyles(work.status)}>
                <span className="sct-status-dot" style={{ backgroundColor: getStatusStyles(work.status).color }}></span>
                {statusOptions.find(opt => opt.value === work.status)?.label}
              </div>
              <div className="sct-grade">
                <strong>{getGradeText(work.grade, work.gradeCategory)}</strong>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentCourseworkTable;
