import React from 'react';
import './StudentCourseCard.css';
import tickIcon from '../assets/tick.svg';
import clockIcon from '../assets/clock.svg';

interface StudentCourseCardProps {
  courseTitle: string;
  teacherName: string;
  submissionPercentage: number;
  inTimeSubmissionPercentage: number;
  statusClass: string;
  onClick: () => void;
}

const StudentCourseCard: React.FC<StudentCourseCardProps> = ({
  courseTitle,
  teacherName,
  submissionPercentage,
  inTimeSubmissionPercentage,
  statusClass,
  onClick,
}) => {
  return (
    <div className="student-course-card" onClick={onClick}>
      <div className="student-course-card-title">{courseTitle}</div>
      <div className="student-course-card-content">
        <div className="student-course-card-teacher-container">
          <div className="student-course-card-teacher">{teacherName}</div>
        </div>
        <div className="student-course-card-percentage-status-container">
          <div className="student-course-card-percentage-container">
            <img
              src={tickIcon}
              alt="Odevzdáno"
              className="student-course-card-icon"
              title="Odevzdáno"
            />
            <div className="student-course-card-percentage">
              {submissionPercentage.toFixed(1)} %
            </div>
          </div>
          <div className="student-course-card-percentage-container">
            <img
              src={clockIcon}
              alt="Odevzdáno včas"
              className="student-course-card-icon"
              title="Odevzdáno včas"
            />
            <div className="student-course-card-percentage">
              {inTimeSubmissionPercentage.toFixed(1)} %
            </div>
          </div>
          <div className={`student-course-card-status ${statusClass}`} />
        </div>
      </div>
    </div>
  );
};

export default StudentCourseCard;
