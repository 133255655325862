// App.tsx
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Menu from './components/Menu';
import Footer from './components/Footer';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import './App.css'; // Import the new CSS file for layout

const clientId = "535096964886-ql3e03rgnm66bn6bqnuhl8spndg6bqtn.apps.googleusercontent.com";

const App = () => {
    const [lastUpdate, setLastUpdate] = useState<string | null>(null);

    // Function to fetch the last update
    const fetchLastUpdate = async () => {
        try {
            const response = await fetch('/api/updated');
            if (response.ok) {
                const data = await response.json();
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                setLastUpdate(new Date(data.last_update).toLocaleTimeString("cs-CZ", { timeZone: userTimeZone }));
            } else {
                console.error('Failed to fetch last update');
            }
        } catch (error) {
            console.error('Error fetching last update:', error);
        }
    };

    useEffect(() => {
        fetchLastUpdate(); // Fetch on initial render

        // Set interval to fetch every minute
        const interval = setInterval(fetchLastUpdate, 60000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Router>
                <div className="app-wrapper"> {/* Wrapper div for flexbox layout */}
                    <Menu />
                    <Container className="content" style={{ marginTop: '20px' }}>
                        <Routes>
                            <Route index element={<Home />} />
                            <Route path="/home" element={<Home />} />
                        </Routes>
                    </Container>
                    <Footer lastUpdate={lastUpdate} />
                </div>
            </Router>
        </GoogleOAuthProvider>
    );
};

export default App;
